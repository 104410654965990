import React from 'react';
import PropTypes, { any } from 'prop-types';
import Order from '../Order';
import { OrderSide } from 'opensea-js/lib/types';
import { connectWallet, BOT_ID, CATEGORY, DAPP_LINK, FILTER_PROPERTIES } from '../../constants';
import Account from '../Account'

export default class Log extends React.Component {
  static propTypes = {
    seaport: PropTypes.object.isRequired,
    accountAddress: PropTypes.string
  };

  state = {
    bot_id: BOT_ID,
    orders: undefined,
    total: 0,
    side: undefined,
    onlyForMe: false,
    onlyByMe: false,
    onlyBundles: false,
    page: 1,
    assetsWithoutOrders: undefined,
    price_min: '',
    price_max: '',
    params: {},
    wallet: false,
   // filters: {coloring_strategy: '', shading: '', layout: '', framed: '', scene: '', palette: ''},
    //toggle: FILTER_PROPERTIES,
    filters: {
      shading: '',
      layout: '',
      framed: '',
      scene: '',
      palette: '',
      coloring_strategy: ''
    },
    toggle: {
      shading: false,
      layout: false,
      framed: false,
      scene: false,
      palette: false,
      coloring_strategy: false
    },
    tab: 'assets',
    buyInProgress: false,
    connected: false
  };

  componentDidMount() {
    this.fetchData({});
  }


  async fetchData(params) {
    var self = this;

    var stored_price_min = this.getCookie('price_min');
    var stored_price_max = this.getCookie('price_max');
    

    var stored = [];

    console.log(Object.keys(FILTER_PROPERTIES));
    /*
    for (const [key, value] of Object.entries(FILTER_PROPERTIES)) {
      console.log(key, value);
      stored[key] = this.getCookie(key);

      params[key] = stored[key];
      this.state.params[key] = stored[key];
      this.setState({ key: stored[key] });
    }
    */

    if (stored_price_min) {
      params.price_min = stored_price_min;
      this.state.params.price_min = stored_price_min;
      this.setState({ price_min: stored_price_min })
    }

    if (stored_price_max) {
      params.price_max = stored_price_max;
      this.state.params.price_max = stored_price_max;
      this.setState({ price_max: stored_price_max });
    }

    var stored_shading = this.getCookie('shading');
    var stored_layout = this.getCookie('layout');
    var stored_framed = this.getCookie('framed');
    var stored_scene = this.getCookie('scene');
    var stored_palette = this.getCookie('palette');
    var stored_coloring = this.getCookie('coloring_strategy');

    if (stored_coloring) {
      params.coloring_strategy = stored_coloring;
      this.state.params.coloring_strategy = stored_coloring;
      this.setState({ coloring_strategy: stored_coloring });
    }

    if (stored_shading) {
      params.shading = stored_shading;
      this.state.params.shading = stored_shading;
      this.setState({ shading: stored_shading });
    }

    if (stored_layout) {
      params.layout = stored_layout;
      this.state.params.layout = stored_layout;
      this.setState({ layout: stored_layout });
    }
    if (stored_framed) {
      params.framed = stored_framed;
      this.state.params.framed = stored_framed;
      this.setState({ framed: stored_framed });
    }
    if (stored_scene) {
      params.scene = stored_scene;
      this.state.params.scene = stored_scene;
      this.setState({ scene: stored_scene });
    }
    if (stored_palette) {
      params.palette = stored_palette;
      this.state.params.palette = stored_palette;
      this.setState({ palette: stored_palette });
    }
    


    var link = 'https://api-dev.brn.ai/products/' + BOT_ID + '?category=' + CATEGORY + '&limit=200';

    var query = '';

    if (params.price_min !== undefined && params.price_min != '') {
      if (params.price_max !== undefined && params.price_max != '') {
        if (params.price_min == '') {
          params.price_min = 0;
        }
        query += '&price=from' + params.price_min + 'to' + params.price_max;
      } else {
        query += '&price=gte' + params.price_min;
      }
    }

    /*
    for (const [key, value] of Object.entries(FILTER_PROPERTIES)) {
      if (params[key] !== undefined && params[key] != '' && params[key] !== null) {
        query += '&' + key + '=^' + params[key] + '$';
      }
    }
    */

    if (params.shading !== undefined && params.shading != '') {
      query += '&shading=^' + params.shading + '$';
    }

    if (params.scene !== undefined) {
      query += '&scene=' + params.scene;
    }

    if (params.layout !== undefined && params.layout != '') {
      query += '&layout=^' + params.layout + '$';
    }

    if (params.palette !== undefined && params.palette != '') {
      query += '&palette=^' + params.palette + '$';
    }

    if (params.framed !== undefined && params.framed != '') {
      query += '&framed=^' + params.framed + '$';
    }

    if (params.coloring_strategy !== undefined && params.coloring_strategy != '') {
      query += '&coloring-strategy=^' + params.coloring_strategy + '$';
    }
    /*
   

    if (params.property !== undefined) {
      query += '&property=' + params.property;
    }
    */

    link += query;

    fetch(link)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        self.state.assetsWithoutOrders = data.products;
        self.setState({ assetsWithoutOrders: data.products, total: 20 })
      })

    fetch('https://api-dev.brn.ai/products/' + BOT_ID + '/filters?fields=' + Object.keys(FILTER_PROPERTIES).join() + '&category=' + CATEGORY)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        data.filters['coloring_strategy'] = data.filters['coloring-strategy'];
        self.state.filters = data.filters;
        self.setState({ filters: data.filters })
        console.log(self.state.filters);
      })

    const { accountAddress } = this.props;
    if (accountAddress) {
      this.setCookie('wallet', accountAddress, 30);
      var wallet = accountAddress.substring(0, 6) + "..." + accountAddress.slice(-4);
      this.setState({ wallet: wallet });
    } else {
      var stored_wallet = this.getCookie('wallet');
      if (stored_wallet) {
        var wallet = stored_wallet.substring(0, 6) + "..." + stored_wallet.slice(-4);
        this.setState({ wallet: wallet });
      }
    }
  }

  async connect() {
    if (!window.ethereum) {
      window.location.href = "https://metamask.app.link/dapp/" + DAPP_LINK;
      return;
    }

    const { accountAddress } = this.props

    if (!accountAddress) {
      // await connectWallet();
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const accountAddress = accounts[0];

      this.setCookie('wallet', accountAddress, 30);

      var wallet = accountAddress.substring(0, 6) + "..." + accountAddress.slice(-4);
      this.setState({ wallet: wallet });

    } else {
      var wallet = accountAddress.substring(0, 6) + "..." + accountAddress.slice(-4);
      this.setState({ wallet: wallet });
    }

  }

  async disconnect() {
    this.setCookie('wallet', '', 30);
    window.location.reload();
  }

  paginateTo(page) {
    this.setState({ orders: undefined, page }, () => this.fetchData({}))
  }

  toggleSide(side) {
    if (this.state.side === side) {
      side = undefined
    }
    this.setState({
      orders: undefined,
      side,
      onlyForMe: undefined
    }, () => this.fetchData({}))
  }

  async toggleForMe() {
    const { accountAddress } = this.props
    if (!accountAddress) {
      await connectWallet()
    }
    const { onlyForMe } = this.state
    this.setState({
      orders: undefined,
      onlyForMe: !onlyForMe,
      onlyByMe: false,
      // Doesn't make sense to show sell orders the user makes
      side: onlyForMe ? undefined : OrderSide.Buy,
    }, () => this.fetchData({}))
  }

  toggleBundles() {
    const { onlyBundles } = this.state
    this.setState({
      orders: undefined,
      onlyBundles: !onlyBundles,
      onlyByMe: false,
      // Only sell-side for now
      side: OrderSide.Sell,
    }, () => this.fetchData({}))
  }

  async toggleByMe() {
    const { accountAddress } = this.props
    if (!accountAddress) {
      await connectWallet()
    }
    const { onlyByMe } = this.state
    this.setState({
      orders: undefined,
      onlyByMe: !onlyByMe,
      onlyForMe: false
    }, () => this.fetchData({}))
  }


  handleChange = (e, field) => {
    this.state.assetsWithoutOrders = null;

    this.state.params[field] = e.target.value
    this.setCookie(field, e.target.value, 7);
    this.setState({ field: e.target.value });
    this.fetchData(this.state.params);

  }

  filterBy = (field, value) => {
    var self = this;

    this.setCookie(field, value, 7);

    if (this.state.params[field] && this.state.params[field] === value) {
      this.state.params[field] = '';
      this.setState({ field: '' });
      this.state.assetsWithoutOrders = null;
      this.setCookie(field, '', 7);

      setTimeout(function () {
        self.fetchData(self.state.params);
      }, 300);

      return;
    }

    this.state.params[field] = value;
    this.setState({ field: value });
    this.state.assetsWithoutOrders = null;

    setTimeout(function () {
      self.fetchData(self.state.params);
    }, 300);
  }

  toggleProperties = (property) => {
    this.state.toggle[property] = !this.state.toggle[property];
    this.setState({ toggle: this.state.toggle });
    this.setCookie('toggle_' + property, this.state.toggle[property], 7);

  }
  goTo = (tab) => {
    this.state.tab = tab;
    this.setState({ tab: tab });
    console.log(this.state);
  }

  setCookie = (name, value, days) => {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }



  async fulfillOrder(asset) {
    this.setState({ buyInProgress: true });

    if (asset.attributes.pay_with_credit_card) {
      window.location.href = 'https://buy.brn.ai/payment/' + this.state.bot_id + '/' + asset.product_id;
      return;
    }

    //   const { accountAddress } = this.props

    const order = await this.props.seaport.api.getOrder({ side: 1, asset_contract_address: asset.external.data.asset_contract_address, token_id: asset.external.data.token_id });

    try {
      if (!accountAddress) {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        var accountAddress = accounts[0];
      }

      await this.props.seaport.fulfillOrder({ order, accountAddress })
    } catch (error) {
      if (error != 'Error: No wallet address found') {
        this.setState({ buyInProgress: false });
        alert(error);
      }
    }
  }

  render() {
    const { assetsWithoutOrders, filters } = this.state

    return (
      <div class="wrapper">
        <div class="container">
          <header _ngcontent-cfe-c28="" className="app-header">
            <a _ngcontent-cfe-c28="" href="#" className="logo">
              <img className="logo" _ngcontent-cfe-c28="" src="/Cerby-Logo.png" alt="logo" />
            </a>
            <div className="logo-text">
              <div className="slogan">Cerby Sea</div>
            </div>

            {this.state.wallet && (
              <div className="wallet">{this.state.wallet} <span onClick={() => this.disconnect()} class="material-icons">cancel</span></div>
            )}

            {!this.state.wallet && (
              <button onClick={() => this.connect()} className="wallet">Connect wallet</button>
            )}

          </header>
          <div className="mobile-filters">
            <div className={"" + (this.state.tab == 'assets' ? "filter-active" : "")} onClick={() => this.goTo('assets')}>Assets</div>
            <div className={"" + (this.state.tab == 'filters' ? "filter-active" : "")} onClick={() => this.goTo('filters')}>Filters</div>
          </div>

          <div className="content">
            {filters != null

              ? <React.Fragment>

                <div className={"filters " + (this.state.tab == 'filters' ? "m-show" : "m-hide")}>
                  <div className="filter-row">
                    <div className="filter-name">Price</div>
                    <div className="filter-val">MIN</div>
                    <input className="filter-input" type="text" defaultValue={this.state.price_min} placeholder="Not set" onKeyUp={(e) => this.handleChange(e, 'price_min')} />
                    <div className="filter-val">MAX</div>
                    <input className="filter-input" type="text" defaultValue={this.state.price_max} placeholder="Not set" onKeyUp={(e) => this.handleChange(e, 'price_max')} />
                  </div>

                  <div className="filter-row">
                    <div className="filter-name">Properties</div>
                    {filters.coloring_strategy && filters.coloring_strategy.length && (
                      <React.Fragment>
                        <div className="filter-val" onClick={() => this.toggleProperties('coloring_strategy')}>Coloring strategy</div>

                        <div className={(this.state.toggle.coloring_strategy === true ? "f-show" : "f-hide")}>
                          {filters.coloring_strategy.map((coloring_strategy, i) => {
                            return (
                              <React.Fragment>
                                <div className={"filter-option " + (this.state.params.coloring_strategy == coloring_strategy ? "active" : "")} onClick={() => this.filterBy('coloring_strategy', coloring_strategy)}>
                                  {this.state.params.coloring_strategy == coloring_strategy ? (
                                    <React.Fragment>
                                      <span class="material-icons">
                                        check_box
                                      </span>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <span class="material-icons">
                                        check_box_outline_blank
                                      </span>
                                    </React.Fragment>
                                  )}
                                  <span className="fil-text">{coloring_strategy}</span>
                                </div>
                              </React.Fragment>
                            )
                          })}
                        </div>


                      </React.Fragment>
                    )}

                    {filters.shading && filters.shading.length && (
                      <React.Fragment>
                        <div className="filter-val" onClick={() => this.toggleProperties('shading')}>shading</div>

                        <div className={(this.state.toggle.shading === true ? "f-show" : "f-hide")}>
                          {filters.shading.map((shading, i) => {
                            return (
                              <React.Fragment>
                                <div className={"filter-option " + (this.state.params.shading == shading ? "active" : "")} onClick={() => this.filterBy('shading', shading)}>
                                  {this.state.params.shading == shading ? (
                                    <React.Fragment>
                                      <span class="material-icons">
                                        check_box
                                      </span>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <span class="material-icons">
                                        check_box_outline_blank
                                      </span>
                                    </React.Fragment>
                                  )}
                                  <span className="fil-text">{shading}</span>
                                </div>
                              </React.Fragment>
                            )
                          })}
                        </div>


                      </React.Fragment>
                    )}

                    {filters.framed && filters.framed.length && (
                      <React.Fragment>
                        <div className="filter-val" onClick={() => this.toggleProperties('framed')}>framed</div>
                        <div className={(this.state.toggle.framed === true ? "f-show" : "f-hide")}>
                          {filters.framed.map((framed, i) => {
                            return (
                              <React.Fragment>
                                <div className={"filter-option " + (this.state.params.framed == framed ? "active" : "")} onClick={() => this.filterBy('framed', framed)}>
                                  {this.state.params.framed == framed ? (
                                    <React.Fragment>
                                      <span class="material-icons">
                                        check_box
                                      </span>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <span class="material-icons">
                                        check_box_outline_blank
                                      </span>
                                    </React.Fragment>
                                  )}
                                  <span className="fil-text">{framed}</span>
                                </div>
                              </React.Fragment>
                            )
                          })}
                        </div>
                      </React.Fragment>
                    )}

                    {filters.scene && filters.scene.length && (
                      <React.Fragment>
                        <div className="filter-val" onClick={() => this.toggleProperties('scene')}>scene</div>
                        <div className={(this.state.toggle.scene === true ? "f-show" : "f-hide")}>
                          {filters.scene.map((scene, i) => {
                            return (
                              <React.Fragment>
                                <div className={"filter-option " + (this.state.params.scene == scene ? "active" : "")} onClick={() => this.filterBy('scene', scene)}>
                                  {this.state.params.scene == scene ? (
                                    <React.Fragment>
                                      <span class="material-icons">
                                        check_box
                                      </span>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <span class="material-icons">
                                        check_box_outline_blank
                                      </span>
                                    </React.Fragment>
                                  )}
                                  <span className="fil-text">{scene}</span>
                                </div>
                              </React.Fragment>
                            )
                          })}
                        </div>
                      </React.Fragment>
                    )}

                  {filters.palette && filters.palette.length && (
                      <React.Fragment>
                        <div className="filter-val" onClick={() => this.toggleProperties('palette')}>palette</div>
                        <div className={(this.state.toggle.palette === true ? "f-show" : "f-hide")}>
                          {filters.palette.map((palette, i) => {
                            return (
                              <React.Fragment>
                                <div className={"filter-option " + (this.state.params.palette == palette ? "active" : "")} onClick={() => this.filterBy('palette', palette)}>
                                  {this.state.params.palette == palette ? (
                                    <React.Fragment>
                                      <span class="material-icons">
                                        check_box
                                      </span>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <span class="material-icons">
                                        check_box_outline_blank
                                      </span>
                                    </React.Fragment>
                                  )}
                                  <span className="fil-text">{palette}</span>
                                </div>
                              </React.Fragment>
                            )
                          })}
                        </div>
                      </React.Fragment>
                    )}

                    {filters.layout && filters.layout.length && (
                      <React.Fragment>
                        <div className="filter-val" onClick={() => this.toggleProperties('layout')}>layout</div>
                        <div className={(this.state.toggle.layout === true ? "f-show" : "f-hide")}>
                          {filters.layout.map((layout, i) => {
                            return (
                              <React.Fragment>
                                <div className={"filter-option " + (this.state.params.layout == layout ? "active" : "")} onClick={() => this.filterBy('layout', layout)}>
                                  {this.state.params.layout == layout ? (
                                    <React.Fragment>
                                      <span class="material-icons">
                                        check_box
                                      </span>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <span class="material-icons">
                                        check_box_outline_blank
                                      </span>
                                    </React.Fragment>
                                  )}
                                  <span className="fil-text">{layout}</span>
                                </div>
                              </React.Fragment>
                            )
                          })}
                        </div>
                      </React.Fragment>
                    )}
                  </div>

                </div>

              </React.Fragment>
              : <div className="text-center">Loading...</div>
            }

            {assetsWithoutOrders != null

              ? <React.Fragment>
                <div className={"cards " + (this.state.tab == 'assets' ? "m-show" : "m-hide")}>

                  {assetsWithoutOrders.map((asset, i) => {
                    return (
                      <React.Fragment>
                        <div class="card">
                          <a target="_blank" rel="noopener noreferrer" href="#">
                            <img class="avatar"
                              alt="Asset artwork"
                              src={asset.attributes.image} />
                          </a>

                          <div class="price">
                            {asset.price} {asset.attributes.currency}
                          </div>

                          <div class="title">{asset.name}.</div>


                          <div title={asset.attributes.description} class="info">{asset.attributes.description.substr(0, 81)}...</div>

                          {asset.attributes.owner && asset.attributes.owner.username && (
                            <div class="owner">
                              <div className="avt" style={{ backgroundImage: `url(${asset.attributes.owner.image})` }}>
                              </div>
                              <div className="owner-name"><div className="name">{asset.attributes.owner.username}</div><div className="role">owner</div>
                              </div>
                            </div>
                          )}

                          {asset.attributes.price && (
                            <button onClick={() => this.fulfillOrder(asset)} className="btn">Buy{this.state.buyInProgress && (<span>ing</span>)} for {asset.price} {asset.attributes.currency}{this.state.buyInProgress && (<span>...</span>)}</button>
                          )}

                        </div>

                      </React.Fragment>
                    )
                  })}
                </div>
              </React.Fragment>

              : <div className="text-center lll">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

              </div>
            }
          </div>


        </div>
      </div>
    );
  }
}
/*

       {orders && orders.map((order, i) => {
                    return <Order {...this.props} key={i} order={order} />
                  })}

                <div class="card">
                              <a target="_blank" rel="noopener noreferrer" href={asset.permalink}>
                                  <img class="avatar"
                                  alt="Asset artwork"
                                  src={asset.image_url} />
                              </a>


                              <div class="price"> </div>
                              <div class="title">{asset.name}.</div>
                              <div class="info">{asset.description}</div>

                          </div>

                    */